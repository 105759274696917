/*
#181818 -background 1
#212121 -background 2
#FFFFFF -text
*/

@media screen and (max-width: 960px) {
  * {
    -webkit-overflow-scrolling: touch;
  }
}
html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
}

.app {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  /* min-height: 100%; */
  width: 100%;
  overflow: hidden;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* .services {
  background-image: url('./assets/images/sample\ \(8\).jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('./assets/images/sample-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./assets/images/sample\ \(9\).jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */
