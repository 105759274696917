@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap');

:root {
  --primary: #fff;
}

@font-face {
  font-family: 'Spy Agency';
  src: local('Spy Agency'),
    url(../assets/font/spyagencyv3.ttf) format('truetype');
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: var(--primary);
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--nav {
  /* filter: drop-shadow(2px 3px 15px black); */
  background-color: transparent;
  color: var(--primary);
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-family: 'Spy Agency';
  margin-top: 1.5vh;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background: var(--primary);
  color: #242424;
  transition: all 0.3s ease-out;
}
