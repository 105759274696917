@import url(https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Proza+Libre:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Audiowide&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Proza+Libre:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Proza+Libre:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Proza+Libre:wght@800&display=swap);
/*
#181818 -background 1
#212121 -background 2
#FFFFFF -text
*/

@media screen and (max-width: 960px) {
  * {
    -webkit-overflow-scrolling: touch;
  }
}
html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
}

.app {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  /* min-height: 100%; */
  width: 100%;
  overflow: hidden;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* .services {
  background-image: url('./assets/images/sample\ \(8\).jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('./assets/images/sample-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./assets/images/sample\ \(9\).jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

html {
  overflow: hidden;
  height: 100%;
}
.container {
  height: 100vh;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-size: 3vh;
  background: linear-gradient(to bottom right, #181818, rgb(65, 65, 65));
}
#circular-text {
  /* color: whitesmoke; */
  position: relative;

  /* padding: 1.56vw; */

  background-color: linear-gradient(to bottom right, #181818, rgb(65, 65, 65));

  /* Character styling */

  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px rgba(225, 255, 255, 0.6);

  animation: spin 15s linear infinite;
}

#circular-text span {
  position: absolute;

  /* position: relative; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* top: 6.385vh;
  left: -6.385vh; */
  /* top: 22.34vh; */
  /* left: 23.72vh; */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* 1920 x 940 */
.logo {
  position: relative;
  /* width: 120px; */
  width: 12.77vh;
  /* height: 120px; */

  height: 12.77vh;
  /* margin-left: -8.513vh; */
  /* margin-top: -6.385vh; */
  /* Circle radius - half width*/
  /* margin-left: calc(-11vh + 6.385vh); */
  /* Circle radius - half height*/
  /* margin-top: calc(-11vh + 6.385vh); */

  /* margin-right: -6.385vh; */
  /* margin-top: -6.385vh; */
  /* margin-bottom: -6.385vh; */
  /* margin-left: -29.585vh; */
  /* margin-right: 14.7vh; */
  /* margin-top: 0vh; */
  /* margin-right: 4.17vw; */
  /* top: 43.3%;
    left: 46.8%; */
  /* transform: translate(-50%, -50%); */

  background-blend-mode: multiply;
  background-size: cover;
  animation: spinneg 15s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinneg {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

body {
  overflow: hidden;
}

video {
  position: fixed;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background: fixed;
}

::-webkit-scrollbar {
  width: 0px !important;
}

.section {
  /* border-top: 2px solid #262626; */
  top: 0px;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-blend-mode: multiply;
  overflow: hidden;
  scroll-snap-align: center;
}

.full-card-container {
  /* z-index: 5; */
  /* padding-top: 75px; */
  /* height: 400vh;
    width: 100%;
    align-items: center; */
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  position: relative;
  width: 101%;
  height: 100vh;
  overflow: auto;
}

.img-card {
  position: relative;
  width: 100%;
  height: 25%;
  background-blend-mode: multiply;
}

.sub-title {
  font-size: 40px;
}

/* CSS for headers/ paragraphs */

.content-h,
.content-p {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  position: absolute;
  color: #fff;
}

.content-h {
  margin-top: 80vh;
  margin-left: 5vw;
  font-size: 80px;
}

.content-p {
  margin-top: 90vh;
  margin-left: 5vw;
  font-size: 20px;
}

/* .hero-h, .hero-p {
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
                               
                               
    text-shadow:
    3px 3px 0 #000,
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
} */

/* .hero-h {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
}  */

/* .hero-p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
} */

.hero-h {
  font-family: 'Proza Libre', sans-serif;
  margin-top: 30vh;
  letter-spacing: -0.3vw;
  word-spacing: 0.15vw;
  line-height: 1.2;
  font-size: 4.5vw;
  overflow-y: hidden;
}

.hero-p {
  /* overflow-y: hidden; */
  font-family: 'Exo', sans-serif;
  margin-left: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 0.7vw;
  line-height: 0;
  font-size: 3vw;
}

.dynamic-btn {
  position: relative;

  background-color: transparent;
  color: var(--primary);
  padding: 8px 20px;
  border: 1px solid var(--primary);
  /* transition: all 0.3s ease-out; */
  font-family: 'Exo', sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.dynamic-btn:hover {
  background-color: var(--primary);
  color: #000;
  transition: all 0.3s ease-out;
}

.hero-h,
.hero-p {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: center;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  position: relative;
  color: #fff;
  background: transparent;
}

.hero-scroll {
  cursor: pointer;
  font-family: 'Exo', sans-serif;
  margin-left: 1.5vw;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  letter-spacing: 0.7vw;
  line-height: 0;
  font-size: 3vw;
  text-align: center;
  position: absolute;
  left: 47.5vw; /* calc(50vw - 1.5vw (margin-left) - 1.5vw (font-size / 2) + 0.5 (looks better)); */
  color: #fff;
  opacity: 0.6;
  animation: HoverMotion 2.5s ease-in-out infinite;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll-mobile {
  visibility: hidden;
  cursor: pointer;

  position: absolute;
  left: calc(50vw - 0.6vh - 2px); /* Half of width = 0.6vh + 2px */
  margin-bottom: 80px;
  color: #fff;
  opacity: 1;

  bottom: 0;

  /* Shape */
  padding-left: 0.6vh;
  padding-right: 0.6vh;
  height: 2.5vh;
  border-radius: 9px;

  border: 2px white solid;
}

.scroll-mobile-anim {
  visibility: hidden;
  position: relative;

  color: #fff;
  opacity: 1;

  width: 3px;
  height: 3px;
  border-radius: 1px;
  background-color: white;
  /* border-radius: 1px; */

  animation: HoverMotionMobile 2.5s ease-in-out infinite;
}
@keyframes HoverMotionMobile {
  0%,
  100% {
    margin-top: calc(0.25vh + 2px);
  }
  50% {
    margin-top: calc(1.7vh - 2px);
  }
}
@keyframes HoverMotion {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
}

#rentals {
  text-align: center;
}

#services {
  text-align: center;
}

#gallery {
  text-align: center;
}

@media screen and (max-width: 960px) {
  .hero-scroll {
    visibility: hidden;
  }

  .scroll-mobile {
    visibility: visible;
  }
  .scroll-mobile-anim {
    visibility: visible;
  }
}

:root {
  --primary: #fff;
}

@font-face {
  font-family: 'Spy Agency';
  src: local('Spy Agency'),
    url(/static/media/spyagencyv3.93eca4dd.ttf) format('truetype');
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  color: var(--primary);
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--nav {
  /* filter: drop-shadow(2px 3px 15px black); */
  background-color: transparent;
  color: #fff;
  color: var(--primary);
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-family: 'Spy Agency';
  margin-top: 1.5vh;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background: #fff;
  background: var(--primary);
  color: #242424;
  transition: all 0.3s ease-out;
}

/* @import url('../assets/font/spyagencyv3.ttf'); */

@font-face {
  font-family: 'Spy Agency';
  src: local('Spy Agency'),
    url(/static/media/spyagencyv3.93eca4dd.ttf) format('truetype');
}

.text-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  justify-content: center;
}

.text-fragment-top {
  font-family: 'Spy Agency', sans-serif;
  color: whitesmoke;
  text-transform: uppercase;

  letter-spacing: -0.3vw;
  word-spacing: 0.15vw;
  line-height: 0.9;
  font-size: 6vw;

  margin-bottom: -2vw;
  /* margin-top: ; */

  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 60%);

          clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 60%);
}

.text-full {
  font-family: 'Spy Agency', sans-serif;
  color: whitesmoke;
  text-transform: uppercase;

  letter-spacing: -0.3vw;
  word-spacing: 0.15vw;
  line-height: 0.9;
  font-size: 6vw;

  margin-bottom: -0.5vw;
  /* margin-top: -40px; */
  z-index: 3;
}

.text-fragment-bottom {
  font-family: 'Spy Agency', sans-serif;
  color: whitesmoke;
  text-transform: uppercase;

  letter-spacing: -0.3vw;
  word-spacing: 0.15vw;
  line-height: 0.9;
  font-size: 6vw;

  margin-top: -2vw;
  margin-bottom: -0.5vw;

  -webkit-clip-path: polygon(0% 40%, 100% 40%, 100% 100%, 0% 100%);

          clip-path: polygon(0% 40%, 100% 40%, 100% 100%, 0% 100%);
}

.outline {
  color: transparent;
  -webkit-text-stroke: 3px whitesmoke;
}

@media screen and (max-width: 960px) {
  .text-fragment-bottom {
    font-size: 10vw;
  }

  .text-full {
    font-size: 10vw;
  }

  .text-fragment-top {
    font-size: 10vw;
  }
}

@font-face {
  font-family: 'Spy Agency';
  src: local('Spy Agency'),
    url(/static/media/spyagencyv3.93eca4dd.ttf) format('truetype');
}

.navbar {
  background: transparent;
  font-family: 'Spy Agency';
  text-transform: uppercase;
  height: 10vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  position: fixed;
  top: 0;
  z-index: 999;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.navbar.active {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
}

.navbar-container {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  /* filter: drop-shadow(8px 5px 15px black); */
  color: #fff;
  /* justify-content: start; */
  left: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.logo-image {
  max-width: 50px;
  margin-right: 10px;
}

.logo-name {
  max-width: 200px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  /* filter: drop-shadow(2px 3px 15px black); */
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  /* border-bottom: 4px solid rgb(129, 102, 102); */
  /* border-bottom: 2px solid black; */
  margin-top: -7px;
  padding-bottom: 20px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  margin-top: 3px;
  color: #fff;
}

.menu-icon {
  display: none;
}

.navbar-mobile {
  display: none;
}

/* @media screen and (max-width: 960px) {
  .nav-menu {
    grid-template-columns: repeat(3, auto);
  }
} */

@media screen and (max-width: 960px) {
  .navbar-container {
    display: none;
    visibility: hidden;
  }

  .navbar-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* position: fixed; */
    height: 6vh;
    width: 100vw;

    background-color: #181818;
    border-top: 1px solid gray;

    opacity: 0.7;

    position: fixed;
    bottom: 0px;
    z-index: 99999;
  }

  .mobile-link-wrapper {
    flex-grow: 1;

    height: 4.5vw;
    width: 4.5vw;
  }

  .nav-mobile-icon {
    flex-grow: 1;
    height: 4.5vw;
    width: 4.5vw;

    -webkit-filter: invert(100%);

            filter: invert(100%);

    cursor: pointer;
    transition: all 2s ease-in-out;
  }

  .nav-mobile-icon:hover {
    transform: scale(1.15);
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  background-color: #181818;
}

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: black 1;
  border-radius: 4px;
}

.rentals-page-container {
  width: 100vw;
  height: calc(100vh - 100px);
  background-color: #181818;
  padding-top: 100px;
  position: relative;

  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
}

.drop-container {
  height: 5vh;
  width: 80vw;
  /* left: 5vw; */
  /* align-items: center;
  align-content: center; */
  text-align: left;
  vertical-align: center;
  /* border: 2px #5a0001 solid; */
  /* border-radius: 10px; */
  position: relative;
  margin-top: 15px;
  cursor: pointer;

  background-color: #212121;
  border: 1px gray solid;
  /* box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.5); */
  border-radius: 3px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.sub-drop-container {
  height: 5vh;
  width: 75vw;
  /* left: 5vw; */
  /* align-items: center;
  align-content: center; */
  text-align: left;
  vertical-align: center;
  /* border: 2px #5a0001 solid; */
  /* border-radius: 10px; */
  position: relative;
  margin-top: 15px;
  cursor: pointer;

  background-color: #212121;
  border: 1px gray solid;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 3px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.rental-container {
  /* position: relative; */

  height: 5vh;
  width: 70vw;
  /* left: 5vw; */
  /* align-items: center;
  align-content: center; */
  text-align: center;
  vertical-align: center;
  /* border: 2px #5a0001 solid; */
  /* border-radius: 10px; */
  position: relative;
  margin-top: 15px;

  background-color: #212121;
  border-radius: 5px;
  border: 1px gray solid;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  /* box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(1, 1, 0.1),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */
}
.nav-space {
  position: absolute;
  height: 80px;
  z-index: 100;
  background-color: #22181c;
}
.display-text {
  font-family: 'Exo', sans-serif;
  font-size: calc(12px + 0.6vw);
  min-width: 20px;
  font-weight: bold;
  color: #ffff;
  padding: 5px 5px 5px 5px;
  line-height: 5vh;
  /* margin-left: 100px; Leave space for a drop down arrow */
}

.description {
  font-family: 'Exo', sans-serif;
  font-size: calc(10px + 1vw);
  font-weight: bold;
  color: #5a0001;
  padding: 5px 5px 5px 5px;
}

.bottom-margin {
  position: relative;
  width: 100%;
  height: 7vh;
}

.service-name {
  position: relative;
  top: 20vh;
  font-family: 'Exo', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.7vw;
  line-height: 0;
  font-size: 3vw;
  z-index: 1;
  text-align: center;
  color: white;
  /* Creates 1 pixel thin black border around text */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.service-description {
  margin-left: 25vw;
  margin-right: 20vw;
  font-family: 'Proza Libre', sans-serif;
  margin-top: 33vh;
  letter-spacing: -0.1vw;
  word-spacing: 0.15vw;
  line-height: 1.7;
  font-size: 1vw;
  z-index: 2;
}

.services-page-container {
  width: 100vw;
  /* height: calc(100vh - 80px); */

  height: 100vh;
  background-color: whitesmoke;
  /* padding-top: 100px; */
  position: relative;

  overflow-y: scroll;
  overflow-x: hidden;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.services-container {
  width: 100vw;
  /* height: calc(100vh - 80px); */
  height: 100vh;

  background-color: #181818;
  position: absolute;

  overflow-y: hidden;
  overflow-x: hidden;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.display-box {
  position: absolute;
  width: 100vw;
  height: 40vh;
  background-color: whitesmoke;
}

.row-break {
  flex-basis: 100%;
  width: 0;
}

/* ServicesNav */

.sub-nav-container {
  position: absolute;
  background-color: #212121;

  top: 40vh;
  width: 100vw;
  height: 60px;
  border: 1px solid grey;
  color: white;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  font-family: 'Audiowide', cursive;
  font-size: 0.9rem;
}

.sub-nav-item {
  padding-right: 4vw;
  padding-left: 4vw;
  cursor: pointer;
  /* border-right: 1px solid black; */
}
.sub-nav-item:after {
  border-left: 3px solid black;
}

.sub-nav-separator {
  border-left: 1px solid white;
  border-right: 1px solid white;
  color: transparent;
}

.sub-nav-separator-left {
  border-right: 1px solid white;
  color: transparent;
}

.sub-nav-separator-right {
  border-left: 1px solid white;
  color: transparent;
}

@media screen and (min-width: 960px) {
  .sub-nav-item:hover {
    margin-top: -2px;
    padding-bottom: 10px;
    transition: all 0.6s ease-out;
  }
}

@media screen and (max-width: 960px) {
  .service-name {
    font-size: 6vw;
  }
}

#root {
  overflow-x: hidden;
}
::-webkit-scrollbar-track {
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  background-color: #181818;
}

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: black 1;
  border-radius: 4px;
}

.gallery-page-container {
  height: calc(100vh - 100px);
  width: 100vw;
  padding-top: 100px;

  background-color: #181818;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;

  justify-content: center;
}

.nav-cover {
  position: fixed;
  height: 10vh;
  width: 100vw;
  background-color: #181818;
}

.img-container {
  height: 20vw;
  width: 20vw;
  margin: 20px 20px 20px 20px;
  border: solid 2px white;
}

.img-placeholder {
  height: 20vw;
  width: 20vw;
  margin: 20px 20px 20px 20px;
  visibility: hidden;
}

.flex-break {
  flex-basis: 100%;
}

