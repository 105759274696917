html {
  overflow: hidden;
  height: 100%;
}
.container {
  height: 100vh;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-size: 3vh;
  background: linear-gradient(to bottom right, #181818, rgb(65, 65, 65));
}
#circular-text {
  /* color: whitesmoke; */
  position: relative;

  /* padding: 1.56vw; */

  background-color: linear-gradient(to bottom right, #181818, rgb(65, 65, 65));

  /* Character styling */

  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px rgba(225, 255, 255, 0.6);

  animation: spin 15s linear infinite;
}

#circular-text span {
  position: absolute;

  /* position: relative; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* top: 6.385vh;
  left: -6.385vh; */
  /* top: 22.34vh; */
  /* left: 23.72vh; */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* 1920 x 940 */
.logo {
  position: relative;
  /* width: 120px; */
  width: 12.77vh;
  /* height: 120px; */

  height: 12.77vh;
  /* margin-left: -8.513vh; */
  /* margin-top: -6.385vh; */
  /* Circle radius - half width*/
  /* margin-left: calc(-11vh + 6.385vh); */
  /* Circle radius - half height*/
  /* margin-top: calc(-11vh + 6.385vh); */

  /* margin-right: -6.385vh; */
  /* margin-top: -6.385vh; */
  /* margin-bottom: -6.385vh; */
  /* margin-left: -29.585vh; */
  /* margin-right: 14.7vh; */
  /* margin-top: 0vh; */
  /* margin-right: 4.17vw; */
  /* top: 43.3%;
    left: 46.8%; */
  /* transform: translate(-50%, -50%); */

  background-blend-mode: multiply;
  background-size: cover;
  animation: spinneg 15s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinneg {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
