@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Proza+Libre:wght@800&display=swap');

::-webkit-scrollbar-track {
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  background-color: #181818;
}

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: black 1;
  border-radius: 4px;
}

.rentals-page-container {
  width: 100vw;
  height: calc(100vh - 100px);
  background-color: #181818;
  padding-top: 100px;
  position: relative;

  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
}

.drop-container {
  height: 5vh;
  width: 80vw;
  /* left: 5vw; */
  /* align-items: center;
  align-content: center; */
  text-align: left;
  vertical-align: center;
  /* border: 2px #5a0001 solid; */
  /* border-radius: 10px; */
  position: relative;
  margin-top: 15px;
  cursor: pointer;

  background-color: #212121;
  border: 1px gray solid;
  /* box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.5); */
  border-radius: 3px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.sub-drop-container {
  height: 5vh;
  width: 75vw;
  /* left: 5vw; */
  /* align-items: center;
  align-content: center; */
  text-align: left;
  vertical-align: center;
  /* border: 2px #5a0001 solid; */
  /* border-radius: 10px; */
  position: relative;
  margin-top: 15px;
  cursor: pointer;

  background-color: #212121;
  border: 1px gray solid;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 3px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.rental-container {
  /* position: relative; */

  height: 5vh;
  width: 70vw;
  /* left: 5vw; */
  /* align-items: center;
  align-content: center; */
  text-align: center;
  vertical-align: center;
  /* border: 2px #5a0001 solid; */
  /* border-radius: 10px; */
  position: relative;
  margin-top: 15px;

  background-color: #212121;
  border-radius: 5px;
  border: 1px gray solid;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  /* box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(1, 1, 0.1),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */
}
.nav-space {
  position: absolute;
  height: 80px;
  z-index: 100;
  background-color: #22181c;
}
.display-text {
  font-family: 'Exo', sans-serif;
  font-size: calc(12px + 0.6vw);
  min-width: 20px;
  font-weight: bold;
  color: #ffff;
  padding: 5px 5px 5px 5px;
  line-height: 5vh;
  /* margin-left: 100px; Leave space for a drop down arrow */
}

.description {
  font-family: 'Exo', sans-serif;
  font-size: calc(10px + 1vw);
  font-weight: bold;
  color: #5a0001;
  padding: 5px 5px 5px 5px;
}

.bottom-margin {
  position: relative;
  width: 100%;
  height: 7vh;
}
