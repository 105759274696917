#root {
  overflow-x: hidden;
}
::-webkit-scrollbar-track {
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  background-color: #181818;
}

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: black 1;
  border-radius: 4px;
}

.gallery-page-container {
  height: calc(100vh - 100px);
  width: 100vw;
  padding-top: 100px;

  background-color: #181818;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;

  justify-content: center;
}

.nav-cover {
  position: fixed;
  height: 10vh;
  width: 100vw;
  background-color: #181818;
}

.img-container {
  height: 20vw;
  width: 20vw;
  margin: 20px 20px 20px 20px;
  border: solid 2px white;
}

.img-placeholder {
  height: 20vw;
  width: 20vw;
  margin: 20px 20px 20px 20px;
  visibility: hidden;
}

.flex-break {
  flex-basis: 100%;
}
