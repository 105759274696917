@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Proza+Libre:wght@800&display=swap');

body {
  overflow: hidden;
}

video {
  position: fixed;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background: fixed;
}

::-webkit-scrollbar {
  width: 0px !important;
}

.section {
  /* border-top: 2px solid #262626; */
  top: 0px;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-blend-mode: multiply;
  overflow: hidden;
  scroll-snap-align: center;
}

.full-card-container {
  /* z-index: 5; */
  /* padding-top: 75px; */
  /* height: 400vh;
    width: 100%;
    align-items: center; */
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  scroll-snap-type: y mandatory;
  position: relative;
  width: 101%;
  height: 100vh;
  overflow: auto;
}

.img-card {
  position: relative;
  width: 100%;
  height: 25%;
  background-blend-mode: multiply;
}

.sub-title {
  font-size: 40px;
}

/* CSS for headers/ paragraphs */

.content-h,
.content-p {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  position: absolute;
  color: #fff;
}

.content-h {
  margin-top: 80vh;
  margin-left: 5vw;
  font-size: 80px;
}

.content-p {
  margin-top: 90vh;
  margin-left: 5vw;
  font-size: 20px;
}

/* .hero-h, .hero-p {
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
                               
                               
    text-shadow:
    3px 3px 0 #000,
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
} */

/* .hero-h {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
}  */

/* .hero-p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
} */

.hero-h {
  font-family: 'Proza Libre', sans-serif;
  margin-top: 30vh;
  letter-spacing: -0.3vw;
  word-spacing: 0.15vw;
  line-height: 1.2;
  font-size: 4.5vw;
  overflow-y: hidden;
}

.hero-p {
  /* overflow-y: hidden; */
  font-family: 'Exo', sans-serif;
  margin-left: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 0.7vw;
  line-height: 0;
  font-size: 3vw;
}

.dynamic-btn {
  position: relative;

  background-color: transparent;
  color: var(--primary);
  padding: 8px 20px;
  border: 1px solid var(--primary);
  /* transition: all 0.3s ease-out; */
  font-family: 'Exo', sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.dynamic-btn:hover {
  background-color: var(--primary);
  color: #000;
  transition: all 0.3s ease-out;
}

.hero-h,
.hero-p {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: center;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  position: relative;
  color: #fff;
  background: transparent;
}

.hero-scroll {
  cursor: pointer;
  font-family: 'Exo', sans-serif;
  margin-left: 1.5vw;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  letter-spacing: 0.7vw;
  line-height: 0;
  font-size: 3vw;
  text-align: center;
  position: absolute;
  left: 47.5vw; /* calc(50vw - 1.5vw (margin-left) - 1.5vw (font-size / 2) + 0.5 (looks better)); */
  color: #fff;
  opacity: 0.6;
  animation: HoverMotion 2.5s ease-in-out infinite;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll-mobile {
  visibility: hidden;
  cursor: pointer;

  position: absolute;
  left: calc(50vw - 0.6vh - 2px); /* Half of width = 0.6vh + 2px */
  margin-bottom: 80px;
  color: #fff;
  opacity: 1;

  bottom: 0;

  /* Shape */
  padding-left: 0.6vh;
  padding-right: 0.6vh;
  height: 2.5vh;
  border-radius: 9px;

  border: 2px white solid;
}

.scroll-mobile-anim {
  visibility: hidden;
  position: relative;

  color: #fff;
  opacity: 1;

  width: 3px;
  height: 3px;
  border-radius: 1px;
  background-color: white;
  /* border-radius: 1px; */

  animation: HoverMotionMobile 2.5s ease-in-out infinite;
}
@keyframes HoverMotionMobile {
  0%,
  100% {
    margin-top: calc(0.25vh + 2px);
  }
  50% {
    margin-top: calc(1.7vh - 2px);
  }
}
@keyframes HoverMotion {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
}

#rentals {
  text-align: center;
}

#services {
  text-align: center;
}

#gallery {
  text-align: center;
}

@media screen and (max-width: 960px) {
  .hero-scroll {
    visibility: hidden;
  }

  .scroll-mobile {
    visibility: visible;
  }
  .scroll-mobile-anim {
    visibility: visible;
  }
}
